import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./components/pages/home/Home";
import "./styles/index.scss";

function NoMatch() {
  return (
    <div className="NoMatch">
      <div>
        NoMatch
      </div>
      <div>
        <Link to="/">Goto HOME</Link>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fetch/:b64" element={<Home />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
