import { AxiosError } from "axios";
import ApiConfig from "./api-config";
import {
  IDataPayloadImageValidate,
  IDataPayloadSendMail,
  IDataPayloadTaskAutocomplete,
  IDataPayloadTaskBlurProcessing,
  IDataPayloadTaskDoProcess,
  IDataPayloadTaskRunProcess, IDataPayloadTaskStatusProcessing, IDataPayloadTaskWithOtherBackdrop, IDataVerifyCaptcha,
} from "./interfaces";
import loggerBackend from "../util/logger/api/backend";
import ApiI18Config from "./api-i18-config";

const Api = {
  autocompleteMail: async (data: IDataPayloadTaskAutocomplete) => ApiConfig.put("task/autocomplete", data),
  fetchBackdrops: async () => ApiConfig.post("backdrop/list", { ratio: "4:3" }, { responseType: "json" }),
  fetchStaticImage: async (relUrl: string) => ApiConfig.get(relUrl, {}, { responseType: "blob" }),
  imageUpload:
  async (formData: FormData) => ApiConfig.post("image/upload", formData, { "Content-Type": "multipart/form-data" }, { responseType: "json" }),
  imageValidate: async (data: IDataPayloadImageValidate) => ApiConfig.put("image/validate", data),
  sendMail: async (data: IDataPayloadSendMail) => ApiConfig.put("task/mail", data),
  taskCheckDoProcess: async (data: IDataPayloadTaskDoProcess) => ApiConfig.put("task/check-process", data),
  taskCheckStatusProccessing: async (data: IDataPayloadTaskStatusProcessing) => ApiConfig.put("task/status-progress", data),
  taskRunProcess: async (data: IDataPayloadTaskRunProcess) => ApiConfig.post("task/run-process", data),
  taskRunProcessBlur: async (data: IDataPayloadTaskBlurProcessing) => ApiConfig.put("task/blur", data),
  taskRunProcessWithOtherBackdrop: async (data: IDataPayloadTaskWithOtherBackdrop) => ApiConfig.put(`task/backdrop/${data.backdropId}`, data),
};

export const ApiI18 = {
  fetchLangData: async () => ApiI18Config.get("api/v2/i18n/react", {}, { responseType: "json" }),
  fetchLangsList: async () => ApiI18Config.get("api/v2/i18n/react/info", {}, { responseType: "json" }),
  verifyCaptcha: async (data: IDataVerifyCaptcha) => ApiI18Config.post("api/v2/manage/verify-captcha", data, { responseType: "json" }),
};

export const getResponseErrorMessage = (err: AxiosError): string => {
  let message = "";
  try {
    const data = err.response!.data && typeof err.response!.data === "object"
      ? err.response!.data
      : typeof err.response!.data === "string"
        ? JSON.parse(err.response!.data)
        : { message: "Unknown error" };
    loggerBackend.debug("getResponseErrorMessage DATA %j", data);
    if ("message" in data) {
      message = data.message;
    } else if ("error" in data) {
      message = data.error;
    }
  } catch (e) {
    message = "Unknown error";
  }

  return message;
};

export default Api;
