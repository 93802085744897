/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Box, Stack, TextField, CardActions,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { AxiosError } from "axios";
import { logEvent } from "firebase/analytics";
import { FormContext } from "../../common/context/context";
import { TemplateType } from "../../common/enums";
import Api, { ApiI18, getResponseErrorMessage } from "../../network/api";
import loggerFront from "../../util/logger/app/front";
import loggerBackend from "../../util/logger/api/backend";
import { ActionType } from "../../common/enums/action-type";
import LangMessage from "../lang/message";
import { LangHelper } from "../lang/helper";
import { UploadFromProps, FileUploadProps } from "./forms.interface";
import {
  MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT, LICENSES, PRIVATE_POLICY, TERMS_OF_SERVICE, MIN_IMAGE_WIDTH, MIN_IMAGE_HEIGHT,
} from "../../common/constants";
import icons from "../../icons";
import { analytics } from "../../common/firebase";

const UploadForm: React.FC<UploadFromProps> = () => {
  const [error, setError] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorFile, setErrorFile] = React.useState("");
  const [errorFormValidate, setErrorFormValidate] = React.useState("");
  const [errorFromApi, setErrorFromApi] = React.useState("");
  const { state, dispatch } = React.useContext(FormContext);
  const [email, setEmail] = useState(state.email);
  const [templateType] = useState(state.templateType);
  const [filename, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileProp, setFileProp] = useState<FileUploadProps | undefined>(undefined);
  const [fileDataURL, setFileDataURL] = useState<string | null>(null);
  const [dragActive, setDragActive] = React.useState(false);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isValidCaptcha, setCaptchaValid] = React.useState(false);
  const [isShowCaptcha, setShowCaptcha] = React.useState(false);
  const [isSubmitPress, handleSubmitButton] = React.useState(false);
  const [showTutorial, handleShowTutorial] = React.useState(true);

  const checkImageProp = () => {
    let res = false;
    if (!fileProp) {
      setErrorFormValidate(LangHelper.getLangMessageValue("demoai.uploadFrom.needValidImageError", state.lang));
      handleSubmitButton(false);
    } else if (fileProp.width > MAX_IMAGE_WIDTH || fileProp.height > MAX_IMAGE_HEIGHT) {
      setErrorFormValidate(LangHelper.getLangMessageValue("demoai.uploadFrom.highResError", state.lang));
      handleSubmitButton(false);
    // ToDo: fix check algorithm
    } else if (fileProp.width < MIN_IMAGE_WIDTH || fileProp.height < MIN_IMAGE_HEIGHT) {
      setErrorFormValidate(LangHelper.getLangMessageValue("demoai.uploadFrom.lowResError", state.lang));
      handleSubmitButton(false);
    // ToDo: fix check algorithm
    } else {
      res = true;
    }

    return res;
  };

  const handleEmailValidate = () => {
    const isEmail = /^[a-z0-9+.]{1,64}@[a-z0-9.]{1,64}[.]{1}[a-z0-9]{1,64}$/i.test(email);
    if (email.length && !isEmail) {
      setErrorMail(true);
    } else {
      setErrorMail(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (errorMail) {
      handleEmailValidate();
    }
    setEmail(value);
  };

  const handleFileUpload = (files: FileList) => {
    if (!files) {
      return;
    }
    const uploadedFile = files[0];

    if (!uploadedFile.type.match(/image\/(jpg|jpeg|png|webp)/i)) {
      // ToDo: set alert box - Image mime type is not valid
      return;
    }
    setFileDataURL(null);
    const { name, size } = uploadedFile;
    loggerFront.info("size is %s", size);

    setFileName(name);
    setFile(uploadedFile);
  };

  const handleButtonUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFileName("");
    // setEmail("");
    setErrorFile("");
    setErrorFormValidate("");
    setErrorFromApi("");
    handleSubmitButton(false);
    setCaptchaValid(false);
    handleFileUpload(e.target.files);
  };

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e.target) {
          const { result } = e.target;
          if (result && !isCancel) {
            const image = new Image();
            image.src = result.toString();
            image.onload = () => {
              setFileProp({ height: image.height, size: file.size, width: image.width });
            };
            setFileDataURL(result.toString());
          }
        }
      };
      fileReader.readAsDataURL(file);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  useEffect(() => {
    if (fileProp) {
      setErrorFile(!checkImageProp() ? LangHelper.getLangMessageValue("demoai.uploadFrom.needValidImageError", state.lang) : "");
    }
  }, [fileProp]);

  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      handleFileUpload(e.dataTransfer.files);
    }
  };

  const explainApiValidateError = (err: AxiosError): void => {
    switch (err.response!.status) {
      case 406: {
        // Image param is not valid
        logEvent(analytics, "demo_ai_is_not_vehicle", {});
        setErrorFromApi(LangHelper.getLangMessageValue("demoai.uploadFrom.photoOfVehicleIsMissing", state.lang));
        handleSubmitButton(false);
        break;
      }
      case 412: {
        // Image param is not valid
        setErrorFile(getResponseErrorMessage(err));
        handleSubmitButton(false);
        break;
      }
      case 423: {
        // Your quota is over
        logEvent(analytics, "demo_ai_quota_is_over", {});
        setErrorFromApi(LangHelper.getLangMessageValue("demoai.uploadFrom.quotaIsOver", state.lang));
        handleSubmitButton(false);
        break;
      }
      default:
        // Unknown error
        setErrorFromApi(LangHelper.getLangMessageValue("demoai.uploadFrom.smthWentWrong", state.lang));
        handleSubmitButton(false);
    }
  };

  const doApiValidate = async (filePropParams: FileUploadProps) => {
    try {
      const response = await Api.imageValidate({
        email,
        photoType: "Car",
        templateType,
        ...filePropParams,
      });

      loggerBackend.debug("Response %j", response);
    } catch (err) {
      if (err instanceof AxiosError) {
        explainApiValidateError(err);
        loggerBackend.error("Axios error is %j", err);
      } else {
        loggerBackend.error("Error is %j", err);
      }
    }
    loggerBackend.debug("After Response");
  };

  const doApiUpload = async () => {
    const formData = new FormData();
    formData.append("fileData", file!);
    formData.append("email", email);
    formData.append("lang", state.lang);
    formData.append("photoType", "Car");
    formData.append("templateType", TemplateType.COMPLETE_CUT);
    try {
      const response = await Api.imageUpload(formData);
      loggerBackend.debug("Response %j", response);
      dispatch({
        fileDemo: {
          ...fileProp!,
          file: file!,
          fileDataURL: fileDataURL!,
          fileName: filename,
          uuid: response?.data?.message,
        },
        type: ActionType.UPLOAD_COMPLETE,
      });
      logEvent(analytics, "demo_ai_upload", {});
    } catch (err) {
      if (err instanceof AxiosError) {
        explainApiValidateError(err);
        loggerBackend.error("Axios error is %j", err);
      } else {
        loggerBackend.error("Error is %j", err);
      }
    }
    loggerBackend.debug("After Response");
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    handleSubmitButton(true);
    e.preventDefault();
    setShowCaptcha(true);

    // uncomment for using local without captcha check
    // let res = true;
    // if (res && !checkImageProp()) {
    //   res = false;
    // }

    // if (res && !doApiValidate(fileProp!)) {
    //   res = false;
    //   loggerFront.info("(!) Backend say - file is not valid");
    // }

    // if (res) {
    //   setError(false);
    //   doApiUpload().catch();
    // }

    // setError(true);
  };

  const onCaptchaChange = async () => {
    if (captchaRef?.current) {
      const recaptcha = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (recaptcha) {
        let success = false;
        try {
          const res = await ApiI18.verifyCaptcha({ recaptcha });

          success = res.data.payload;
        } catch (err) {
          loggerBackend.error("Error is %j", err);
        }
        setCaptchaValid(success);
        if (success) {
          let res = true;
          if (res && !checkImageProp()) {
            res = false;
          }

          if (res && !doApiValidate(fileProp!)) {
            res = false;
            loggerFront.info("(!) Backend say - file is not valid");
          }

          if (res) {
            setError(false);
            doApiUpload().catch();
          }

          setError(true);
        }
      }
    }
  };

  const handleResetForm = () => {
    handleSubmitButton(false);
    setCaptchaValid(false);
    setFileDataURL(null);
    setFile(null);
    setFileName("");
    // setEmail("");
    setErrorFile("");
    setErrorFormValidate("");
    setErrorFromApi("");
  };

  const blockSubmit = !!errorFile.length || !filename || !email || errorMail || isSubmitPress;
  const showWarning = (errorFile.length || errorFromApi || errorFormValidate || errorMail);
  const warningMessage = errorFormValidate || (errorFile || (errorFromApi || (!state.langList?.length || (
    errorMail ? LangHelper.getLangMessageValue("demoai.uploadFrom.notValidEmail", state.lang) : ""))));
  const showMailAndSend = !isShowCaptcha && !errorFile.length && !errorFromApi && !!state.langList?.length && !errorFormValidate;
  const showUploadOther = errorFile || errorFromApi || errorFormValidate;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      component="form"
      onSubmit={handleSubmitForm}
      onReset={handleResetForm}
    >
      <FormControl error={error} variant="filled" fullWidth>
        <Box
          sx={{
            backgroundColor: dragActive ? "#F7F8FA" : "#fff",
            border: !fileDataURL ? "2px dotted #D6DBE6" : "none",
            mb: 2,
          }}
          className="drag"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {filename
            ? (
              <Box justifyContent="center">
                {fileDataURL
                  ? (
                    <Card className="card card--ready">
                      {fileProp
                        ? (
                          <CardMedia
                            component="img"
                            className={isSubmitPress ? "drag drag--big drag--blur img-fullwidth" : "drag--big img-fullwidth"}
                            image={fileDataURL || ""}
                            alt="gad24 photo car"
                          />
                        ) : null}
                      {showUploadOther && (
                        <CardActions>
                          <label className="btn_above_center">
                            <LangMessage msgKey="demoai.uploadFrom.uploadAnotherPhoto" />
                            <input
                              type="file"
                              accept="image/jpeg,image/png,image/webp"
                              hidden
                              onChange={handleButtonUpload}
                            />

                          </label>
                        </CardActions>
                      )}
                    </Card>
                  )
                  : null}
              </Box>
            ) : (
              <div className="drag drag--big">

                {state.langList?.length
                  ? showTutorial ? (
                    <Stack direction="column" className="tutorial">
                      <div className="tutorial_div">
                        <div className="tutorial_text">
                          <LangMessage
                            msgKey="demoai.uploadFrom.tutorialText"
                            defaultValue="Before you proceed to upload..."
                          />

                        </div>
                        <button
                          type="button"
                          onClick={() => handleShowTutorial(false)}
                          className="nowrap gad-btn gad-btn-primary gad-btn-h50 tutorial_btn"
                        >
                          <LangMessage msgKey="demoai.uploadFrom.iUnderstandAndProceed" defaultValue="I understand and proceed" />
                        </button>
                      </div>
                    </Stack>
                  )
                    : (
                      <Stack direction="column" style={{ height: "100%", width: "100%" }}>
                        <label htmlFor="upload_button" className="button_as_link button_as_link_label">
                          <div className="upload-image" />
                          <Typography variant="body2" className="button_as_link button_as_link_gray">
                            <LangMessage msgKey="demoai.uploadFrom.dragCarImageHerOr" />
                          </Typography>
                          <Typography variant="body2" className="button_as_link button_as_link_blue">
                            <LangMessage msgKey="demoai.uploadFrom.browseFile" />
                          </Typography>
                          {filename}
                        </label>
                        <input
                          id="upload_button"
                          type="file"
                          accept="image/jpeg,image/png,image/webp"
                          hidden
                          onChange={handleButtonUpload}
                        />
                      </Stack>
                    ) : <span className="loader" />}
              </div>
            )}
        </Box>
        {showMailAndSend && (
          <>
            <div className="mail_box">
              <Typography variant="body2" className="txt txt_gray">
                <LangMessage msgKey="demoai.uploadFrom.enterEmail" />
                {" "}
                <span className="red">*</span>
              </Typography>
            </div>
            <Stack
              gap="20px"
              direction={{ md: "row", sm: "column" }}
              justifyContent="space-between"
            >
              <TextField
                required
                id="email"
                placeholder="example@mail.com"
                name="email"
                value={email}
                type="email"
                style={{ fontStyle: email ? "normal" : "italic" }}
                className="email_input"
                onChange={handleEmailChange}
                onBlur={handleEmailValidate}
              />
              <button
                type="submit"
                disabled={blockSubmit}
                className="nowrap gad-btn gad-btn-primary gad-btn-h50"
              >
                <LangMessage msgKey="demoai.uploadFrom.sendPhotoToAi" />
              </button>
            </Stack>
          </>
        )}
        {showWarning && (
          <Stack spacing={1} direction="row" className="max_width">
            <img src={icons.warningIcon} alt="warning" />
            <span className="warning_style">
              {state.langList?.length ? <LangMessage msgKey="demoai.common.error" /> : <span className="loader" />}
              {warningMessage}
            </span>
          </Stack>
        )}
        {!isValidCaptcha && isShowCaptcha && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY || "6LdP4S8kAAAAACbrVXW-DwjDHpkE8LGJb-G7R0a1"}
            ref={captchaRef}
            onChange={onCaptchaChange}
            style={{ alignSelf: "center", paddingTop: "20px" }}
          />
        )}
      </FormControl>
      {!!state.langList?.length && (
        <Typography gutterBottom component="small" className="copyright_text">
          <LangMessage msgKey="demoai.uploadFrom.byUploadingAgreeOurOr" />
          {" "}
          <a href={TERMS_OF_SERVICE} target="_blank" rel="noreferrer"><LangMessage msgKey="demoai.main.termsOfService" /></a>
          {" "}
          <LangMessage msgKey="demoai.uploadFrom.and" />
          {" "}
          <a href={PRIVATE_POLICY} target="_blank" rel="noreferrer"><LangMessage msgKey="demoai.uploadFrom.privacyPolicy" /></a>
          .
          <br />
          {" "}
          <LangMessage msgKey="demoai.uploadFrom.alsoSeeInformation" />
          {" "}
          <a href={LICENSES} target="_blank" rel="noreferrer"><LangMessage msgKey="demoai.uploadFrom.licenses" /></a>
          .

        </Typography>
      )}
    </Box>
  );
};

export default UploadForm;
