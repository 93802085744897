/* eslint-disable global-require */

export interface IIcons {
  [key: string]: string;
}

const Icons: IIcons = {
  apple: require("../assets/apple.svg").default,
  flag_cs: require("../assets/icons/flag_cs.svg").default,
  flag_de: require("../assets/icons/flag_de.svg").default,
  flag_en: require("../assets/icons/flag_en.svg").default,
  flag_es: require("../assets/icons/flag_es.svg").default,
  flag_fr: require("../assets/icons/flag_fr.svg").default,
  flag_it: require("../assets/icons/flag_it.svg").default,
  flag_pl: require("../assets/icons/flag_pl.svg").default,
  flag_pt: require("../assets/icons/flag_pt.svg").default,
  flag_sk: require("../assets/icons/flag_sk.svg").default,
  flag_sl: require("../assets/icons/flag_sl.svg").default,
  flag_uk: require("../assets/icons/flag_uk.svg").default,

  google: require("../assets/google.svg").default,
  mainLogo: require("../assets/gad-demo-logo.svg").default,
  orders: require("../assets/icons/menu-icon_orders.svg").default,
  rejectedBlue: require("../assets/icons/menu-icon_rejected_blue.svg").default,
  tutorial: require("../assets/tutorial.png").default,
  warningIcon: require("../assets/icons/menu-icon_rejected.svg").default,
};

export default Icons;
