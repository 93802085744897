/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Box, Grid,
} from "@mui/material";
import UploadFormProvider from "../../forms/UploadFormProvider";
import icons from "../../../icons/index";
import HomeRenderCases from "./HomeRenderCases";
import {
  ANDROID_B2B_LINK, IOS_B2B_LINK,
} from "../../../common/constants";
import LangSwitcher from "../../lang/switcher";

function Home() {
  return (
    <UploadFormProvider>
      <div className="Home">
        <div className="play_store">
          <LangSwitcher />
          <div className="play_store_img">
            <a href={ANDROID_B2B_LINK} target="_blank" rel="noreferrer">
              <img
                style={{ width: "100%" }}
                src={icons.google}
                alt="Google Play"
              />
            </a>
          </div>
          <div className="play_store_img">
            <a href={IOS_B2B_LINK} target="_blank" rel="noreferrer">
              <img style={{ width: "100%" }} src={icons.apple} alt="App Store" />
            </a>
          </div>

        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className="login-paper"
          >
            <Box className="main_box">
              <img
                src={icons.mainLogo}
                className="main_logo"
                alt=""
              />
            </Box>
            <HomeRenderCases />
          </Box>
        </Grid>
      </div>
    </UploadFormProvider>
  );
}

export default Home;
