/* eslint-disable react/no-danger */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import { FormContext } from "../../common/context/context";
import { LangHelper } from "./helper";
import { ILangMessageProps } from "./lang.interface";

const LangMessage: React.FC<ILangMessageProps> = (props) => {
  const { msgKey, defaultValue } = props;
  const { state } = React.useContext(FormContext);
  const [value, setValue] = React.useState(defaultValue || msgKey);

  useEffect(() => {
    const langValue = LangHelper.getLangMessageValue(msgKey, state.lang, defaultValue);
    setValue(langValue);
  }, [state]);

  return (
    <>
      {" "}
      <span dangerouslySetInnerHTML={{ __html: value }} />
      {" "}
    </>
  );
};

export default LangMessage;
