import Debug from "debug";

// Disable socket-io: -socket.io-client:*,-socket.io-parser:*,-engine.io-client:*
localStorage.debug = "app*,-socket.io-client:*,-socket.io-parser:*,-engine.io-client:*";

const DebugSettings = Debug("app");

// Globale on/off debug
DebugSettings.enabled = process.env.NODE_ENV !== "production";

export default DebugSettings;
