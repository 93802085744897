/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum HTTP_METHODS {
   DELETE = "DELETE",
   GET = "GET",
   PATCH = "PATCH",
   POST = "POST",
   PUT = "PUT",
  }
