import axios, { AxiosPromise, AxiosRequestHeaders, Method } from "axios";
import { HTTP_METHODS } from "../common/enums/http-methods";
import { I18nLangs } from "../common/enums/i18-langs";
import { LocalStorageKeys } from "../common/enums/local-storage-keys";

export const baseURL = process.env.REACT_APP_API_I18_URL || "https://dev.gad24.tools/";

class ApiI18Config {
  static headers(headersParams: AxiosRequestHeaders) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headersParams,
    };
  }

  static async get(route: string, key?: AxiosRequestHeaders, options?: any): Promise<AxiosPromise<any>> {
    return this.request(route, null, HTTP_METHODS.GET, key, options);
  }

  static async post<D = any>(route: string, params: D, key?: AxiosRequestHeaders, options?: any): Promise<AxiosPromise<any>> {
    return this.request(route, params, HTTP_METHODS.POST, key, options);
  }

  static async request<D = any>(route: string, params: D, verb: Method, key?: AxiosRequestHeaders, opts?: any): Promise<AxiosPromise<any>> {
    if (route.match("http(s)?://")) {
      const url = `${route}`;
      const options = { method: verb, params };

      return axios(url, options);
    }
    const url = `${baseURL}${route}`;
    const options = { data: params, method: verb, ...opts };
    const keys = key ? { ...key } : {};
    if (localStorage.getItem(LocalStorageKeys.SELECTED_LANG)) {
      keys["Content-Language"] = (localStorage.getItem(LocalStorageKeys.SELECTED_LANG) || I18nLangs.EN).toUpperCase();
    }
    options.headers = ApiI18Config.headers(keys);

    return axios(url, options);
  }
}

export default ApiI18Config;
