import React, { useEffect } from "react";
// import { MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import UploadForm from "../../forms/UploadForm";
import { FormContext } from "../../../common/context/context";
import UploadCompleteBox from "../../boxes/UploadCompleteBox";
import FetchPictureBox from "../../boxes/FetchPictureBox";
// import icons from "../../../Icons/index";
import Api from "../../../network/api";
import { ActionType } from "../../../common/enums/action-type";
import LangSwitcher from "../../lang/switcher";
import { HomeRenderCasesParams } from "./home.interface";

const HomeRenderCases: React.FC = () => {
  const { b64 } = useParams<HomeRenderCasesParams>();

  const { state, dispatch } = React.useContext(FormContext);
  useEffect(() => {
    const fetchData = async () => {
      if (b64 && b64 !== "" && !state?.staticImage) {
        try {
          const response = await Api.taskCheckDoProcess({
            uuid: b64,
          });
          if (response.status === 201) {
            dispatch({
              downloadedName: response.data.payload.downloadedName,
              imagePathBlur: response.data.payload.blurImage,
              originalImage: response.data.payload.originalImage,
              staticImage: response.data.payload.staticImage,
              type: ActionType.IMG_ALREADY_READY,
            });
          }
        } catch (e) {
          dispatch({
            type: ActionType.RESET,
          });
          window.location.href = "/";
        }
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  if (state && state.uploadComplete && !state.staticImage) {
    return (
      <UploadCompleteBox />
    );
  }

  if (state && state.uploadComplete && state.staticImage) {
    return (
      <FetchPictureBox b64={b64 || ""} />
    );
  }

  return (
    <UploadForm title="Fill the form" />
  );
};

export default HomeRenderCases;
