import { DEFAULT_LANG, LANG_ORDER } from "../../common/constants";
import { I18nLangs } from "../../common/enums/i18-langs";
import { LocalStorageKeys } from "../../common/enums/local-storage-keys";
import icons from "../../icons";
import { ApiI18 } from "../../network/api";
import { ILangOptionElement } from "./lang.interface";

export class LangHelper {
  public static async setLangData(): Promise<void> {
    const response = await ApiI18.fetchLangData();
    if (response && response.data) {
      if (response.data.code === 200 && response.data.payload) {
        localStorage.removeItem(LocalStorageKeys.LANG_DATA);
        localStorage.setItem(LocalStorageKeys.LANG_DATA, JSON.stringify(response.data.payload));
      }
    }
  }

  public static getLangMessageValue(key: string, lang: I18nLangs, defaultValue?: string): string {
    let response = defaultValue || key;
    try {
      const langDataString: string | null = localStorage.getItem(LocalStorageKeys.LANG_DATA);
      if (langDataString && langDataString.length) {
        const parsed = JSON.parse(langDataString);
        if (parsed) {
          const langObject = parsed[lang];
          if (langObject) {
            response = langObject[key];
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  public static getDefaultLang(): I18nLangs {
    const langDelimiter = "-";
    const selectedLang = localStorage.getItem(LocalStorageKeys.SELECTED_LANG);

    let language = DEFAULT_LANG;
    let shortLang = selectedLang ?? window.navigator.language.toUpperCase();

    if (shortLang.indexOf(langDelimiter) !== -1) {
      shortLang = shortLang.split(langDelimiter)[0] as I18nLangs;
    }

    if (shortLang.indexOf(langDelimiter) !== -1) {
      shortLang = shortLang.split(langDelimiter)[0] as I18nLangs;
    }

    if (Object.values(I18nLangs).includes(shortLang as I18nLangs)) {
      language = shortLang as I18nLangs;
    }

    return language;
  }

  public static sortLangByOrder(langList: ILangOptionElement[]): ILangOptionElement[] {
    const response: ILangOptionElement[] = langList;

    response.sort((a: ILangOptionElement, b: ILangOptionElement) => (
      LANG_ORDER[a.value as I18nLangs] - LANG_ORDER[b.value as I18nLangs]
    ));

    return response;
  }
}
