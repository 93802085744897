/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum I18nLangs {
    CS = "CS",
    DE = "DE",
    EN = "EN",
    ES = "ES",
    FR = "FR",
    IT = "IT",
    PL = "PL",
    PT = "PT",
    SK = "SK",
    SL = "SL",
    // UK = "UK",
}
