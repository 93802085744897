import { LangOrderType } from "../components/lang/lang.interface";
import { I18nLangs } from "./enums/i18-langs";

export const ANDROID_B2B_LINK = "https://play.google.com/store/apps/details?id=tools.gad24.app";
export const IOS_B2B_LINK = "https://apps.apple.com/app/id1510948466";

export const TERMS_OF_SERVICE = "https://pixel24.world/en/terms-and-conditions/";
export const PRIVATE_POLICY = "https://pixel24.world/en/datenschutz/";
export const LICENSES = "https://pixel24.world/en/impressum/";

export const MAX_WAIT_ALIVE = 2 * 60 * 1000;

export const MAX_IMAGE_WIDTH = 1440 * 3;
export const MAX_IMAGE_HEIGHT = 1080 * 3;

export const MIN_IMAGE_WIDTH = 1280;
export const MIN_IMAGE_HEIGHT = 720;

export const DEFAULT_LANG = I18nLangs.EN;

export const LANG_ORDER: LangOrderType = {
  [I18nLangs.EN]: 0,
  [I18nLangs.DE]: 1,
  [I18nLangs.CS]: 2,
  [I18nLangs.FR]: 3,
  [I18nLangs.IT]: 4,
  [I18nLangs.PL]: 5,
  [I18nLangs.PT]: 6,
  // [I18nLangs.UK]: 7,
  [I18nLangs.ES]: 7,
  [I18nLangs.SK]: 8,
  [I18nLangs.SL]: 9,
};
