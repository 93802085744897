import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ExpandMore } from "@mui/icons-material";
import { FormContext } from "../../common/context/context";
import { ActionType } from "../../common/enums/action-type";
import icons from "../../icons/index";
import { ApiI18 } from "../../network/api";
import { LocalStorageKeys } from "../../common/enums/local-storage-keys";
import { ILangOptionElement } from "./lang.interface";
import { LangHelper } from "./helper";
import { I18nLangs } from "../../common/enums/i18-langs";

const LangSwitcher: React.FC = () => {
  const { state, dispatch } = React.useContext(FormContext);

  const newTitle = LangHelper.getLangMessageValue("demoai.website.title", state.lang);
  const newDescription = LangHelper.getLangMessageValue("demoai.website.description", state.lang);

  const changeLanguage = (lang: I18nLangs): void => {
    dispatch({
      lang,
      type: ActionType.CHANGE_LANG,
    });
  };

  const renderLangs = () => (
    <>
      <div className="lang_div">
        {state.langList?.map((lang) => (
          <MenuItem
            key={`${lang.value}`}
            value={`${lang.value}`}
            className={`${state.lang === lang.value ? "active" : ""} language`}
            onClick={() => changeLanguage(lang.value)}
          >
            <img
              src={lang.icoSrc}
              className="lang_flag"
              alt={`${lang.value}`}
            />
            <span className="lang_span">{lang.value}</span>
          </MenuItem>
        ))}
      </div>
      <div className="lang_dropdown">
        <FormControl sx={{ m: 0 }} size="small">
          <Select
            className="lang_select"
            IconComponent={ExpandMore}
            value={`${state.lang}`}
            sx={{
              ".MuiSelect-icon": {
                color: "white",
              },
            }}
          >
            {state.langList?.map((lang) => (
              <MenuItem
                key={`${lang.value}`}
                value={`${lang.value}`}
                onClick={() => changeLanguage(lang.value)}
              >
                <img
                  src={lang.icoSrc}
                  className="lang_flag"
                  alt={`${lang.value}`}
                />
                <span className="lang_span">{lang.value}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Helmet>
        <title>
          {newTitle}
        </title>
        <meta
          name="description"
          content={newDescription}
        />
      </Helmet>
    </>
  );

  useEffect(() => {
    const fetchData = async () => {
      const languages: ILangOptionElement[] = [];
      try {
        const response = await ApiI18.fetchLangsList();
        if (response && response.data) {
          if (response.data.code === 200 && response.data.payload) {
            const { langs, version } = response.data.payload as { langs: I18nLangs[]; version: number };
            langs.forEach((lang: string) => {
              if (lang in I18nLangs) {
                languages.push({
                  icoSrc: icons[String(`flag_${lang.toLowerCase()}`)],
                  value: lang as I18nLangs,
                });
              }
            });
            const existsVersion = parseInt(String(localStorage.getItem(LocalStorageKeys.LANG_VERSION)), 10);

            if ((version && existsVersion && existsVersion < version) || (version && !existsVersion)) {
              localStorage.setItem(LocalStorageKeys.LANG_VERSION, String(version));
              await LangHelper.setLangData().catch(console.error);
              // Force switch to default lang after lang data was getted
              changeLanguage(state.lang);
            }
          }
        }
        dispatch({
          langList: [...LangHelper.sortLangByOrder(languages)],
          type: ActionType.REFRESH_LANG_LIST,
        });
      } catch (e) {
        // dispatch({
        //   type: ActionType.RESET,
        // });
        // window.location.href = "/";
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>{renderLangs()}</>
  );
};

export default LangSwitcher;
