import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBAV4ubt2R_bCm4hCVllkTr6CrUmGm9G4Q",
  appId: "1:878114676190:web:0e8492ab20da819129d14f",
  authDomain: "pixel24-23e51.firebaseapp.com",
  databaseURL: "https://pixel24-23e51.firebaseio.com",
  measurementId: "G-DT99RBFVY9",
  messagingSenderId: "878114676190",
  projectId: "pixel24-23e51",
  storageBucket: "pixel24-23e51.appspot.com",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth, analytics };
