import React, { useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import {
  Box, Card, CardMedia, Stack,
} from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";
import loggerBackend from "../../util/logger/api/backend";
import Api, { getResponseErrorMessage } from "../../network/api";
import { PayloadCompleteImage } from "../../util/url-crypto";
import { FormContext } from "../../common/context/context";
import { ActionType } from "../../common/enums/action-type";
import { LangHelper } from "../lang/helper";
import { stageImage } from "./boxes.interface";
import { MAX_WAIT_ALIVE } from "../../common/constants";
import LangMessage from "../lang/message";
import icons from "../../icons";

const UploadCompleteBox: React.FC = () => {
  const { state, dispatch } = React.useContext(FormContext);
  const [fileDemo] = useState(state.fileDemo);
  const [isAllowStart, setAllowStart] = useState(false);
  const [stageWaitForImage, setStageWaitForImage] = useState<stageImage>("NONE");
  const [aliveWaitForImage, setAliveWaitForImage] = useState<Date>(new Date());
  const [errorFromApi, setErrorFromApi] = useState("");
  const [redirectStaticImage, setRedirectStaticImage] = useState<PayloadCompleteImage | undefined>(undefined);
  const [statusText, setStatusText] = useState(LangHelper.getLangMessageValue("demoai.uploadComplete.processing_10", state.lang));
  const [progressWidth, setProgressWidth] = useState(0);
  let tmrCheckDoStart: any = null;

  const explainApiValidateError = (err: AxiosError): void => {
    switch (err.response!.status) {
      case 400: {
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      case 404: {
        // Task by uuid is not found
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      case 410: {
        // Link is expired
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      case 412: {
        // Task is not a processing state
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      default:
        // Unknown error
        setErrorFromApi(err.message);
    }
  };

  const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef<typeof callback>();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        // @ts-ignore
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);

        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const isAlivedTimeOut = (): boolean => new Date().getTime() - aliveWaitForImage.getTime() < MAX_WAIT_ALIVE;

  if (!fileDemo) {
    return (
      <div><span className="loader" /></div>
    );
  }
  const {
    fileDataURL, uuid,
  } = fileDemo;

  const doApiRunProcess = async () => {
    if (uuid) {
      try {
        setProgressWidth(10);
        const response = await Api.taskRunProcess({
          uuid,
        });

        setAllowStart(true);
        setStatusText(LangHelper.getLangMessageValue("demoai.uploadComplete.processing_10", state.lang));
        setProgressWidth(10);
        loggerBackend.debug("Response %j", response);
      } catch (err) {
        if (err instanceof AxiosError) {
          explainApiValidateError(err);
          loggerBackend.error("Axios error is %j", err);
        } else {
          loggerBackend.error("Error is %j", err);
        }
      }
    }
  };

  const refreshAlivedTime = async () => {
    if (uuid && window.confirm(LangHelper.getLangMessageValue("demoai.uploadFrom.autoCompleteInMail", state.lang))) {
      setProgressWidth(5);
      setStatusText(LangHelper.getLangMessageValue("demoai.uploadFrom.timeExceed", state.lang));
      setStageWaitForImage("WAITING");
      setAliveWaitForImage(new Date());
      await Api.autocompleteMail({ uuid });
      dispatch({
        type: ActionType.RESET,
      });
    } else if (uuid) {
      await Api.autocompleteMail({ uuid });
      dispatch({
        type: ActionType.RESET,
      });
    } else {
      dispatch({
        type: ActionType.RESET,
      });
    }
  };

  const doApiCheckStatusProcessing = async () => {
    if (uuid) {
      try {
        const response = await Api.taskCheckStatusProccessing({
          uuid,
        });
        loggerBackend.debug("Response %j", response);
        if (response.status === 201) {
          loggerBackend.debug("** Task is completed", response);
          loggerBackend.debug("** Task is completed", response.data.payload);

          const res = await Api.taskRunProcessBlur({
            uuid,
          });
          loggerBackend.debug("** Task generating blur completed", res);
          if (res.status === 200) {
            const imagePathBlur = res.data;
            const data: PayloadCompleteImage = response.data.payload;
            data.imagePathBlur = imagePathBlur;
            setAllowStart(false);
            setStatusText(LangHelper.getLangMessageValue("demoai.uploadFrom.processing_90", state.lang));
            setProgressWidth(100);
            setRedirectStaticImage(data);
          }
          setAliveWaitForImage(new Date());
        } else {
          // Check exceed time and clear
          loggerBackend.error("Response TM ", response.data.aliveAt);
          if (response.data.aliveAt) {
            const aliveTm = Date.parse(response.data.aliveAt);
            if (Number.isNaN(aliveTm) === false) {
              setAliveWaitForImage(new Date(aliveTm));
              if (progressWidth < 81) {
                setStatusText(LangHelper.getLangMessageValue(`demoai.uploadFrom.processing_${progressWidth + 10}`, state.lang));
                setProgressWidth(progressWidth + 10);
              }
            }
          }
          if (!isAlivedTimeOut()) {
            setStageWaitForImage("TIME_EXCEED");
            await refreshAlivedTime();
            // send status for sending email after image ready
          }
        }
      } catch (err) {
        setStageWaitForImage("NONE");
        if (err instanceof AxiosError) {
          explainApiValidateError(err);
          loggerBackend.error("Axios error is %j", err);
        } else {
          loggerBackend.error("Error is %j", err);
        }
      }
    }
  };

  const doApiCheckRunProcess = async () => {
    if (uuid) {
      try {
        const response = await Api.taskCheckDoProcess({
          uuid,
        });

        loggerBackend.debug("Response %j", response);
        if (response.status === 201) {
          loggerBackend.debug("** Task is completed", response.data.payload);

          const res = await Api.taskRunProcessBlur({
            uuid,
          });
          loggerBackend.debug("** Task generating blur completed", res);
          if (res.status === 200) {
            const imagePathBlur = res.data;
            const data = response.data.payload;
            data.imagePathBlur = imagePathBlur;
            setAllowStart(false);
            setStatusText(LangHelper.getLangMessageValue("demoai.uploadFrom.processing_90", state.lang));
            setProgressWidth(100);
            setRedirectStaticImage(response.data.payload);
          }
          setAliveWaitForImage(new Date());
        } else {
          setAllowStart(true);
          setStageWaitForImage("WAITING");
          setStatusText(LangHelper.getLangMessageValue("demoai.uploadFrom.processing_30", state.lang));
          setProgressWidth(30);
          setAliveWaitForImage(new Date());
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          explainApiValidateError(err);
          loggerBackend.error("Axios error is %j", err);
        } else {
          loggerBackend.error("Error is %j", err);
        }
      }
    }
  };

  const onClose = async () => {
    if (window.confirm(LangHelper.getLangMessageValue("demoai.uploadFrom.processConfirm", state.lang))) {
      if (uuid) { await Api.autocompleteMail({ uuid }); }
      dispatch({
        type: ActionType.RESET,
      });
    }
  };

  useEffect(() => {
    loggerBackend.debug("Occurs ONCE, AFTER the initial render.");
    if (tmrCheckDoStart === null) {
      tmrCheckDoStart = setTimeout(async () => {
        // Run check api
        await doApiCheckRunProcess();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isAllowStart) {
        try {
          await doApiRunProcess();

          setStatusText(LangHelper.getLangMessageValue("demoai.uploadFrom.processing_30", state.lang));
          setProgressWidth(30);
        } catch (err) {
          loggerBackend.error("Error is %j", err);
        }
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [isAllowStart]);

  useInterval(async () => {
    await doApiCheckStatusProcessing();
  }, stageWaitForImage === "WAITING" && isAlivedTimeOut() ? 5000 : null);

  useEffect(() => {
    if (redirectStaticImage && Object.entries(redirectStaticImage).length !== 0) {
      dispatch({
        downloadedName: redirectStaticImage.downloadedName,
        imagePathBlur: redirectStaticImage.imagePathBlur || "",
        staticImage: redirectStaticImage.staticImage,
        type: ActionType.IMG_READY,
      });
    }
  }, [redirectStaticImage]);

  useEffect(() => {
    const unloadCallback = async (event: any) => {
      event.preventDefault();
      // if (!state.staticImage) {
      //   return;
      // }
      if (uuid) { await Api.autocompleteMail({ uuid }); }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };
    window.addEventListener("beforeunload", unloadCallback);

    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <Box justifyContent="center">
      <Card className="card card--ready">
        {fileDataURL
          ? (
            <CardMedia
              component="img"
              className="drag drag--big drag--blur img-fullwidth"
              image={fileDataURL || ""}
              alt="gad24 photo car blur"
            />
          ) : null}
      </Card>
      <br />
      <div className="progress_field">
        <span
          className="progress_score"
          style={{
            color: `${errorFromApi ? "red" : "#bbc1cc"}`,
          }}
        >
          {statusText}
        </span>
        <div
          className="progress_line"
          style={{
            color: `${errorFromApi ? "red" : "#bbc1cc"}`,
            width: `${errorFromApi ? 0 : progressWidth}%`,
          }}
        />
      </div>
      <button type="button" onClick={onClose} className="btn--close back">
        {" "}
        <NavigateBefore />
        <LangMessage msgKey="demoai.uploadComponent.back" />
        {" "}
      </button>
      {(errorFromApi || !state.langList?.length) && (
        <Stack spacing={1} direction="row">
          <img src={icons.warningIcon} alt="warning" />
          <span
            style={{
              bottom: "-30px",
              fontWeight: "700",
              marginLeft: "10px",
              position: "relative",
            }}
          >
            <LangMessage msgKey="demoai.common.error" />
            {errorFromApi || "connection"}
          </span>
        </Stack>
      )}
    </Box>
  );
};

export default UploadCompleteBox;
