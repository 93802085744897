/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import FileSaver from "file-saver";
import {
  Box, Card, CardActions, CardMedia, Stack,
} from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import { logEvent } from "firebase/analytics";
import loggerBackend from "../../util/logger/api/backend";
import Api, { getResponseErrorMessage } from "../../network/api";
import { FormContext } from "../../common/context/context";
import { baseURL } from "../../network/api-config";
import { ActionType } from "../../common/enums/action-type";
import LangMessage from "../lang/message";
import icons from "../../icons";
import { analytics } from "../../common/firebase";
import { LangHelper } from "../lang/helper";
import { LocalStorageKeys } from "../../common/enums/local-storage-keys";

interface Props {
  b64: string;
}

const FetchPictureBox: React.FC<Props> = ({ b64 }) => {
  const { state, dispatch } = React.useContext(FormContext);
  let [fileDemo] = useState(state.fileDemo);
  // eslint-disable-next-line no-unused-vars
  const [isDoneAttachment, setDoneAttachment] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState("");
  const [isAnswer, showDownloadAndBackdropButton] = React.useState(false);
  const [tabValue, handleChangeTab] = React.useState(1);
  const [backdrops, setBackdropsList] = useState([]);
  const [showBackdrops, openBackdrops] = useState(false);
  const [backdropSelected, chooseBackdrop] = useState(null);
  const [isGenerating, handleGeneration] = useState(false);

  const explainApiValidateError = (err: AxiosError): void => {
    switch (err.response!.status) {
      case 400: {
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      case 404: {
        // Task by uuid is not found
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      case 410: {
        // Link is expired
        setErrorFromApi(getResponseErrorMessage(err));
        break;
      }
      default:
        // Unknown error
        setErrorFromApi(err.message);
    }
  };

  const doApiFetchImage = async () => {
    try {
      const response = await Api.fetchStaticImage(`${tabValue === 1 ? state.staticImage : state.imagePathBlur}`);
      const file = new Blob([response.data]);
      FileSaver.saveAs(file, state.downloadedName);
      setDoneAttachment(true);
      logEvent(analytics, "demo_ai_download", {});
      loggerBackend.debug("Response %j", response);
    } catch (err) {
      if (err instanceof AxiosError) {
        explainApiValidateError(err);
        loggerBackend.error("Axios error is %j", err);
      } else {
        loggerBackend.error("Error is %j", err);
      }
    }
  };

  if (!fileDemo) {
    fileDemo = {
      fileDataURL: `${baseURL}${state.originalImage}` || "",
      fileName: "",
      height: 0,
      size: 0,
      uuid: b64,
      width: 0,
    };
  }
  const {
    fileDataURL, uuid,
  } = fileDemo;

  const handleShowDownloadAndBackdropButton = (value: boolean) => {
    showDownloadAndBackdropButton(value);
  };

  const handleBackdrops = async () => {
    try {
      const response = await Api.fetchBackdrops();

      setBackdropsList(response?.data);
      openBackdrops(true);
    } catch (err) {
      // console.log("Error is %j", err);
    }
  };

  const onCloseBackdrops = () => {
    openBackdrops(false);
  };

  // const removeDuplicates = (array: string[]) => {
  //   const output: string[] = [];
  //   array.forEach((item) => {
  //     if (!output.includes(item)) { output.push(item); }
  //   });

  //   return output;
  // };

  const onClose = async () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        String(LangHelper.getLangMessageValue("demoai.uploadForm.closeConfirm", state.lang)),
      )
    ) {
      // if (b64 && state.isNewImagesGenerated) {
      await Api.sendMail({ lang: localStorage.getItem(LocalStorageKeys.SELECTED_LANG) || state.lang, uuid: uuid || b64 });
      // }
      dispatch({
        type: ActionType.RESET,
      });
      window.location.href = "/";
    }
  };
  useEffect(() => {
    const unloadCallback = async (event: any) => {
      event.preventDefault();

      // if (!state.staticImage) {
      //   return;
      // }

      // if (!b64 || state.isNewImagesGenerated) {
      await Api.sendMail({ lang: localStorage.getItem(LocalStorageKeys.SELECTED_LANG) || state.lang, uuid: uuid || b64 });
      // }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };
    window.addEventListener("beforeunload", unloadCallback);

    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const generateNewImage = async (backdropId: string) => {
    if (uuid) {
      handleGeneration(true);
      const response = await Api.taskRunProcessWithOtherBackdrop({ backdropId, uuid });
      dispatch({
        staticImage: response.data,
        type: ActionType.BACKDROP_READY,
      });
      logEvent(analytics, "demo_ai_backdrop", {});
      onCloseBackdrops();
      handleGeneration(false);
    }
  };

  return (
    <Box justifyContent="center">
      {fileDataURL && !showBackdrops
        ? (
          <>
            <Stack gap="20px" direction="row" className="onlymobile max_width">
              {tabValue === 1 && (
                <button
                  type="button"
                  className="onlymobile gad-btn gad-btn-white-blue gad-btn-fullwidth"
                  onClick={() => handleBackdrops()}
                >
                  <LangMessage msgKey="demoai.uploadFrom.changeBackdrop" defaultValue="" />
                </button>
              )}

              <button
                className="onlymobile gad-btn gad-btn-primary gad-btn-fullwidth"
                disabled={tabValue === 2}
                onClick={() => doApiFetchImage()}
                type="button"
              >
                <LangMessage msgKey="demoai.common.download" defaultValue="" />
              </button>
            </Stack>
            <Card
              className="card card--ready"
              onMouseOver={() => handleShowDownloadAndBackdropButton(true)}
              onMouseLeave={() => handleShowDownloadAndBackdropButton(false)}
              onTouchStart={() => handleShowDownloadAndBackdropButton(true)}
              onTouchEnd={() => handleShowDownloadAndBackdropButton(false)}
              onPointerOver={() => handleShowDownloadAndBackdropButton(true)}
              onPointerLeave={() => handleShowDownloadAndBackdropButton(false)}
            >
              {tabValue === 1
                ? (
                  <CardMedia
                    component="img"
                    className="drag--big"
                    image={`${baseURL}${state.staticImage}` || ""}
                    alt="gad24 photo car"
                  />
                ) : tabValue === 0
                  ? (
                    <CardMedia
                      component="img"
                      className={`drag drag--big ${state.imagePathBlur ? "" : " drag--blur"}`}
                      image={`${baseURL}${state.imagePathBlur}` || fileDataURL || ""}
                      alt="gad24 photo car"
                    />
                  ) : tabValue === 2
                    ? (
                      <CardMedia
                        component="img"
                        className="drag drag--big"
                        image={fileDataURL || `${baseURL}${state.originalImage}` || ""}
                        alt="gad24 photo car"
                      />
                    ) : null}
              {tabValue !== 2 && <div className="drag drag--watermark" />}
              {[0, 1].includes(tabValue) && isAnswer && (
                <CardActions className="card card--over">
                  <div className="btn_above_between">

                    {tabValue === 1 && (
                      <div
                        className="gad-btn gad-btn-white-blue gad-btn-h50 nowrap"
                        onClick={() => handleBackdrops()}
                      >
                        <LangMessage msgKey="demoai.uploadFrom.changeBackdrop" defaultValue="change Backdrop" />
                      </div>
                    )}

                    <div
                      className="gad-btn gad-btn-primary gad-btn-h50"
                      onClick={() => doApiFetchImage()}
                    >
                      <LangMessage msgKey="demoai.common.download" defaultValue="download" />
                    </div>
                  </div>
                </CardActions>
              )}
            </Card>

          </>
        ) : showBackdrops && backdrops?.length ? (
          <Card
            className="card card--backdrops"
          >
            {backdrops.map((backdrop: any) => (
              <Card className="backdrop-field" key={backdrop?.id}>

                <CardMedia
                  component="img"
                  image={`${baseURL}/${backdrop?.preview}` || ""}
                  onClick={() => chooseBackdrop(backdrop?.id)}
                  alt="gad24 backdrop"
                />
                <CardActions
                  sx={{ p: 0 }}
                  className={`img-overlay${backdropSelected === backdrop?.id ? "__check-btn" : ""}`}
                  onClick={() => chooseBackdrop(backdrop?.id)}
                />
              </Card>
            ))}
          </Card>
        )
          : null}
      <Box justifyContent="center" width="100%" className="max_width">
        {!showBackdrops
          ? (
            <>
              <Tabs
                value={tabValue}
                onChange={(e, value) => handleChangeTab(value)}
                className="tab-line"
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              >
                <Tab
                  disableRipple
                  label="blur"
                />
                <Tab
                  disableRipple
                  label="Complete cut"
                />
                <Tab
                  disableRipple
                  label="original"
                />
              </Tabs>
              <Stack spacing={1} direction={{ md: "row", sm: "column" }} className="dashed">
                <div className="register_to">
                  <img src={icons.rejectedBlue} alt="Register to access the full version of our service" />
                  <LangMessage msgKey="demoai.fetchBox.registerTo" defaultValue="registration" />
                </div>
                <a
                  href="https://gad24.tools/registration"
                  target="_blank"
                  rel="noreferrer"
                  className="gad-btn gad-btn-primary gad-btn-h40"
                >

                  <LangMessage msgKey="demoai.fetchBox.joinUs" defaultValue="join us" />
                </a>
              </Stack>

            </>
          ) : (
            <button
              type="button"
              disabled={!backdropSelected && !isGenerating}
              className="gad-btn gad-btn-primary gad-btn-h50 gad-btn-fullwidth"
              onClick={() => (backdropSelected && !isGenerating ? generateNewImage(backdropSelected) : null)}
            >
              {isGenerating ? "" : <LangMessage msgKey="demoai.uploadComponent.generateNewImage" defaultValue="generate New Image" />}
              {isGenerating && <span className="img-hourglass-btn" />}
            </button>
          )}
      </Box>

      <button type="button" onClick={() => (showBackdrops ? onCloseBackdrops() : onClose())} className="btn--close back">
        {" "}
        <NavigateBefore />
        <LangMessage msgKey="demoai.uploadComponent.back" defaultValue="back" />
        {" "}
      </button>
      {(errorFromApi || !state.langList?.length) && (
        <Stack spacing={1} direction="row">
          <img src={icons.warningIcon} alt="warning" />
          <span
            style={{
              bottom: "-30px",
              fontWeight: "700",
              marginLeft: "10px",
              position: "relative",
            }}
          >
            <LangMessage msgKey="demoai.common.error" defaultValue="Error" />
            {errorFromApi || "connection"}
          </span>
        </Stack>
      )}
    </Box>
  );
};

export default FetchPictureBox;
