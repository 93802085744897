import DebugSettings from "./settings";

const LoggerLevel = ["fatal", "error", "warn", "info", "debug"];
const LoggerIcons = ["🔥", "🛑", "⚠️", "ℹ️", ""];

export default class Logger {
  private readonly loggerKey: string;

  private readonly maxLevel: string;

  constructor(level: string, loggerKey: string) {
    this.loggerKey = loggerKey;
    this.maxLevel = level;
  }

  public fatal = (...args: any[]) => this.logger("fatal", ...args);

  public error = (...args: any[]) => this.logger("error", ...args);

  public warn = (...args: any[]) => this.logger("warn", ...args);

  public info = (...args: any[]) => this.logger("info", ...args);

  public debug = (...args: any[]) => this.logger("debug", ...args);

  private logger(level: string, ...args: any[]): void {
    const idxLvl = LoggerLevel.indexOf(level);
    const icon = LoggerIcons[idxLvl];
    const item = DebugSettings.extend(`${this.loggerKey}${level}:${icon}`);
    item.enabled = DebugSettings.enabled && idxLvl <= LoggerLevel.indexOf(this.maxLevel);

    if (args && args.length === 1 && typeof args[0] === "string") {
      item("%s", args[0]);
    } else {
      item(args[0], ...args.splice(1));
    }
  }
}
