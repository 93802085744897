/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum ActionType {
  RESET = "RESET",
  IMG_READY = "IMG_READY",
  BLUR_READY = "BLUR_READY",
  BACKDROP_READY = "BACKDROP_READY",
  CHANGE_LANG = "CHANGE_LANG",
  IMG_ALREADY_READY = "IMG_ALREADY_READY",
  UPLOAD_COMPLETE = "UPLOAD_COMPLETE",
  REFRESH_LANG_LIST = "REFRESH_LANG_LIST"
}
