import React from "react";
import {
  Action, ActionUploadComplete, ContextValue, State,
} from "./context.interface";
import { TemplateType } from "../enums";
import { baseURL } from "../../network/api-config";
import { ActionType } from "../enums/action-type";
import { LocalStorageKeys } from "../enums/local-storage-keys";
import { LangHelper } from "../../components/lang/helper";

export const initialState: State = {
  downloadedName: "ready-ai.jpg",
  email: "",
  fileDemo: undefined,
  isNewImagesGenerated: false,
  lang: LangHelper.getDefaultLang(),
  langList: [],
  templateType: TemplateType.COMPLETE_CUT,
  uploadComplete: false,
};

const contextValue: ContextValue = {
  state: initialState,
  // eslint-disable-next-line sort-keys
  dispatch: () => {},
};

export const FormContext = React.createContext(contextValue);

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...state,
        downloadedName: "",
        // email: "",
        fileDemo: undefined,
        isNewImagesGenerated: false,
        staticImage: "",
        templateType: TemplateType.COMPLETE_CUT,
        uploadComplete: false,
      };
    }
    case ActionType.UPLOAD_COMPLETE: {
      const fileDemo = { ...(action as ActionUploadComplete).fileDemo };

      return {
        ...state,
        fileDemo,
        uploadComplete: true,
      };
    }
    case ActionType.IMG_READY: {
      const { staticImage, imagePathBlur, downloadedName } = action;

      return {
        ...state,
        downloadedName,
        imagePathBlur,
        staticImage,
        uploadComplete: true,
      };
    }
    case ActionType.BLUR_READY: {
      const { imagePathBlur } = action;

      return {
        ...state,
        imagePathBlur,
        uploadComplete: true,
      };
    }
    case ActionType.BACKDROP_READY: {
      const { staticImage } = action;

      return {
        ...state,
        isNewImagesGenerated: true,
        staticImage,
      };
    }
    case ActionType.CHANGE_LANG: {
      const lang = action?.lang;
      localStorage.setItem(LocalStorageKeys.SELECTED_LANG, lang);

      return {
        ...state,
        lang,
      };
    }
    case ActionType.REFRESH_LANG_LIST: {
      const langList = action?.langList;

      return {
        ...state,
        langList,
      };
    }
    case ActionType.IMG_ALREADY_READY: {
      const {
        staticImage, imagePathBlur, downloadedName, originalImage,
      } = action;

      return {
        ...state,
        downloadedName,
        imagePathBlur,
        originalImage,
        staticImage,
        uploadComplete: true,
      };
    }
    default:
      return state;
  }
};
