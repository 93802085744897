import React from "react";
import { initialState, reducer, FormContext } from "../../common/context/context";

type Props = {
  children: React.ReactElement | React.ReactElement[],
}

// eslint-disable-next-line func-names
const UploadFormProvider: React.FC<Props> = function ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FormContext.Provider value={{ dispatch, state }}>
      {children}
    </FormContext.Provider>
  );
};

export default UploadFormProvider;
